import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { t, T } from '@web-solutions/module-localization';

import { Text, Title } from 'core/ui-elements';

import { LandingButton } from 'src/components/landing-button';
import { getPersonalizedData } from 'src/store/profile/selectors';

import { Signs } from '../birth-chart/components/signs/signs';

import { ReactComponent as WorldWide } from './images/worldwide.svg';

import { getFormattedData } from './utils';

import classes from './style.module.scss';

const tKey = 'landing.welcome';

export const Welcome = () => {
  const personalizedData = useSelector(getPersonalizedData);

  const isPersonalized = !!personalizedData;

  const { formattedDate, dayOfWeek, formattedTime } = getFormattedData(personalizedData)

  return <div id={isPersonalized ? 'book' : ''} className={classNames(classes.welcome, { [classes.personal]: isPersonalized })}>
    <div className={classNames(classes.wrapper, { [classes.personal]: isPersonalized })}>
      <div className={classes.content}>
        <Title className={classes.title}>
          <T k={t(`${tKey}.${isPersonalized ? "personal_title" : "title"}`)} />
        </Title>
        {isPersonalized ?
          <div className={classes.personal_bith_info}>
            <p className={classes.info_text}>{t(`${tKey}.date`, { dayName: dayOfWeek, date: formattedDate })} {formattedTime ? t(`${tKey}.time`, { time: formattedTime }) : ''}</p>
            <p className={classes.info_text}>{t(`${tKey}.location`, { location: personalizedData.location.label, interpolation: { escapeValue: false } })}</p>
          </div>
          :
          <Text className={classes.subtitle}>
            {t(`${tKey}.subtitle`)}
          </Text>
        }

        {isPersonalized ?
          <Signs className={classes.signs} skeletonClassName={classes.skeleton} />
          :
          <div className={classes.info}>
            <div className={classes.info_block}>
              <div className={classes.info_block_title}>
                4.8★
              </div>
              <div className={classes.info_block_content}>
                <T k={`${tKey}.info.rating`} />
              </div>
            </div>
            <div className={classes.info_block}>
              <div className={classes.info_block_title}>
                70+
              </div>
              <div className={classes.info_block_content}>
                <T k={`${tKey}.info.count`} />
              </div>
            </div>
            <div className={classes.info_block}>
              <div className={classes.info_block_title}>
                <WorldWide />
              </div>
              <div className={classes.info_block_content}>
                <T k={`${tKey}.info.shipping`} />
              </div>
            </div>
          </div>
        }
      </div>
      <LandingButton analyticCategory='landing_welcome' className={classes.button} />
    </div>
  </div>
}

export default Welcome;