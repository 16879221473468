import _map from 'lodash/map';
import { useState } from 'react';
import { type Swiper as SwiperType } from 'swiper';
import { Thumbs } from 'swiper/modules';

import { T } from '@web-solutions/module-localization';

import { Swiper } from '@web-solutions/core/ui-elements';

import { IMAGES } from './images';

import classes from './style.module.scss';

const tKey = 'landing.book_swiper';

const BookSwiper = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null);

  const slides = _map(IMAGES, (v, k) => (
    <div className={classes.preview}>
      <img src={IMAGES[k].src} srcSet={IMAGES[k].srcSet} alt="pic" />
    </div> 
  ))

  return (
    <div className={classes.wrapper}>
      <p className={classes.title}><T k={`${tKey}.title`} /></p>
      <div className={classes.swiper_wrapper}>
        <Swiper
          pagination={false}
          slidesPerView={1}
          spaceBetween={0}
          slides={slides}
          loop
          modules={[Thumbs]}
          thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
          className={classes.mainSlider}
          watchSlidesProgress={true}
        />
        <Swiper
          pagination={false}
          centeredSlides={false}
          slidesPerView={6.3}
          spaceBetween={0}
          className={classes.thumbsSlider}
          slides={slides}
          onSwiper={setThumbsSwiper}
          loop
        />
      </div>
    </div>
  );
}

export default BookSwiper;