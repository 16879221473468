import { useSelector } from 'react-redux';

import { t } from '@web-solutions/module-localization';
import NatalChart from '@web-solutions/natal-chart';

import { Image } from 'core/ui-elements';

import { getPersonalizedData } from 'src/store/profile/selectors';

import { LandingButton } from 'src/components/landing-button';

import { Signs } from './components/signs/signs';

import { useBirthChart } from './hooks/useBirthChart';

import { IMAGES } from './images';

import classes from './style.module.scss';

const tKey = 'landing.birth_chart';


export const BirthChart = () => {
  const personalizedData = useSelector(getPersonalizedData);
  const isPersonalized = !!personalizedData;
  const data = useBirthChart(personalizedData);

  return <div className={classes.wrapper}>
    <div className={classes.header}>
      <div className={classes.title}>
        {t(`${tKey}.${isPersonalized ? "personalized_title" : "title"}`)}
      </div>
      {isPersonalized ?
        <Signs />
        :
        <div className={classes.text}>
          {t(`${tKey}.subtitle`)}
        </div>
      }
    </div>
    {isPersonalized && data ?
      <div className={classes.chart}>
        <NatalChart houses={data?.houses} planets={data?.planets} mode='shop' />
      </div>
      :
      <Image img={IMAGES.BOOK} alt='birth-chart' />
    }

    <div className={classes.footer}>
      <div className={classes.text}>
        {t(`${tKey}.${isPersonalized ? "personalized_text" : "text"}`)}
      </div>
      <LandingButton className={classes.button} analyticCategory='landing_birth_chart' />
    </div>
  </div>
}

export default BirthChart;