import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Analytics from '@web-solutions/module-analytics';

import { useRemoteConfig } from 'src/hooks/use-remote-config';
import { EVENT_ACTION } from 'src/constants/events';
import { getPersonalizedData } from 'src/store/profile/selectors';
import { PersonalizationPopupContextProvider } from 'src/hooks/use-personalization-popup-context';
import { setCartProducts } from 'src/store/cart/actions';

import { PersonalizationPopUp } from '../personalization';

import { useFixedLanding } from './hooks/use-fixed-landing';

import { SECTIONS } from './sections';

import classes from './style.module.scss';

const Landing = () => {
  const dispatch = useDispatch();

  const { landingStack, presummaryStack, shopMode, product } = useRemoteConfig();

  const personalizedData = useSelector(getPersonalizedData);

  const [isPersonalizationPopupVisible, setIsPersonalizationPopupVisible] = useState(false);

  useFixedLanding(isPersonalizationPopupVisible);

  useEffect(() => {
    Analytics.trackEvent('landing', EVENT_ACTION.OPEN);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleCloseClick = () => {
    setIsPersonalizationPopupVisible(false);
  }

  const handleSubmit = () => {
    if (shopMode === 'auto_add_to_cart') {
      dispatch(setCartProducts([product]));
    }
    setIsPersonalizationPopupVisible(false);
  }

  const renderSections = () => {
    const stack = !personalizedData ? presummaryStack : landingStack;
    return stack.map((item) => {
      const component = SECTIONS[item];

      if (!component) {
        return null;
      }

      return (
        <React.Fragment key={item}>
          {component}
        </React.Fragment>
      );
    });
  }

  return (
    <PersonalizationPopupContextProvider value={{ onOpen: () => setIsPersonalizationPopupVisible(true) }}>
      <div className={classes.container}>
        <div className={classes.wrapper}>
          {renderSections()}
          <PersonalizationPopUp
            visible={isPersonalizationPopupVisible}
            onSubmit={handleSubmit}
            onClose={handleCloseClick}
          />
        </div>
      </div>
    </PersonalizationPopupContextProvider>
  );
};

export default Landing;
