import { useState, useEffect, type BaseSyntheticEvent } from 'react';
import { useSelector } from 'react-redux';

import { getApproximateLocation, getPersonalizedData } from 'src/store/profile/selectors';

import type { PersonalizedData } from 'src/store/profile';

import { checkFormValidity, validateFormField } from '../utils';

export const useForm = () => {
  const formData = useSelector(getPersonalizedData);
  const approximateLocation = useSelector(getApproximateLocation);

  const initialFormData = {
    name: '',
    last_name: '',
    birth_date: {
      day: '',
      month: '',
      year: '',
    },
    birth_time: {
      hour: '',
      minutes: '',
      dont_now: false,
    },
    location: {
      label: approximateLocation?.label || '',
      lat: approximateLocation?.lat || '',
      lon: approximateLocation?.lon || '',
    },
    email: '',
  };

  const [isFormValid, setIsFormValid] = useState(true);

  const [data, setData] = useState<PersonalizedData>(() => formData || initialFormData);

  const [validation, setValidation] = useState({
    name: true,
    last_name: true,
    birth_date: true,
    birth_time: true,
    location: true,
    email: true,
  });

  useEffect(() => {
    if (!isFormValid) {
      setIsFormValid(checkFormValidity(validation))
    }
  }, [isFormValid, validation]);

  const handleChange = (e: BaseSyntheticEvent) => {
    const { name, value } = e.target;

    if (typeof value === 'string') {
      setData({ ...data, [name]: value });

      if (!isFormValid) {
        setValidation((prev) => ({ ...prev, [name]: validateFormField({ value: value.trim(), name }) }))
      }
    }
  };

  const validateFormData = (data: PersonalizedData) => {
    const newValidation = (Object.keys(data) as (keyof PersonalizedData)[]).reduce((acc, fieldName) => {
      const value = data[fieldName];

      const preparedValue = typeof value === 'string' ? value.trim() : value;

      const isValid = validateFormField({ name: fieldName, value: preparedValue });

      return { ...acc, [fieldName]: isValid }
    }, { ...validation })

    setValidation(newValidation);

    return newValidation;
  };

  return {
    validation,
    data,
    isFormValid,
    validateFormData,
    handleChange,
    setValidation,
    setData,
    setIsFormValid
  }
}
