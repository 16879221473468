import { useEffect, useState } from 'react';

import { getBirthChart } from '@wowmaking/birthchart';

import { type PersonalizedData } from 'src/store/profile';

export const useHousesAndPlanetsData = (personalizedData: PersonalizedData | null) => {
  const { birth_date, location, birth_time } = personalizedData || {};

  const [data, setData] = useState<{ house: number, sign: string, name: string }[] | null>(null);

  useEffect(() => {
    const getBirthChartData = async () => {

      if (!birth_date || !location) {
        setData(null);
        return;
      }

      const { year, month, day } = birth_date || {};
      const { lat, lon } = location || {};
      const { hour, minutes } = birth_time || {};

      if (year && month && day && lat != null && lon != null) {
        try {
          const res = await getBirthChart({
            birth: {
              date: `${year}-${month}-${day}`,
              time: `${hour || '12'}:${minutes || '00'}`,
              location: { lat: +lat, lon: +lon },
            },
          });

          if (res.success) {
            const { planetsWithSignsAndHouses } = res.data;
            setData(planetsWithSignsAndHouses);
          }
        } catch (error) {
          console.error("Error fetching birth chart data:", error);
        }
      }
    };

    getBirthChartData();
  }, [birth_date, location, birth_time]);

  return data;
};
