import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { t, T } from '@web-solutions/module-localization';

import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from 'core/constants/general';

import { getShippingMethod } from 'src/store/profile/selectors';
import { setShippingMethod } from 'src/store/profile/actions';
import { type ShippingMethod as ShippingMethodType } from 'src/store/profile';

import { useRemoteConfig } from 'src/hooks/use-remote-config'

import { CompleteButton } from '../complete_button';
import { ErrorBlock } from '../error_block';

import { useFetchShippingMethods } from '../../hooks/use-fetch-shipping-methods';

import { Skeleton } from './components/skeleton';
import { DeliveryMethod } from './components/diliverier';

import classes from './styles.module.scss';

const tKey = 'shipping_method';

export type Method = {
  id: string,
  deliverier: string,
  shipping_level: string,
  delivery_interval: string,
  price: string,
  currency: string,
}

interface Props {
  onChangeStatus: () => void;
}

export const ShippingMethod: React.FC<Props> = ({ onChangeStatus }) => {
  const activeMethodData = useSelector(getShippingMethod);

  const { methodsData: methods, isError } = useFetchShippingMethods();
  const [activeMethod, setActiveMethod] = useState<ShippingMethodType | null>(activeMethodData.id ? activeMethodData : null);
  const dispatch = useDispatch();

  const {
    policyLinks,
  } = useRemoteConfig();

  useEffect(() => {
    window.scrollBy({
      top: -window.innerHeight,
      behavior: 'smooth'
    });
    Analytics.trackEvent('shipping_method', EVENT_ACTION.OPEN);
  }, []);

  useEffect(() => {
    if ((methods && !activeMethod) || methods?.some(m => m !== activeMethod)) {
      const defaultMethod = methods[0];
      setActiveMethod(defaultMethod);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods, dispatch]);


  const handleCompleteButtonClick = () => {
    Analytics.trackEvent('shipping_method', EVENT_ACTION.SUCCESS);
    dispatch(setShippingMethod({
      id: activeMethod!.id,
      deliverier: activeMethod!.deliverier,
      shipping_level: activeMethod!.shipping_level,
      delivery_interval: activeMethod!.delivery_interval,
      price: activeMethod!.price,
      currency: activeMethod!.currency
    }))
    onChangeStatus();
  }

  const handleMethodClick = (id: string) => {
    const selectedMethod = methods?.find(item => item.id === id);
    if (selectedMethod) {
      setActiveMethod(selectedMethod);
    }
  }

  return (
    <div className={classes.shipping_method}>
      <p className={classes.title}>{t(`${tKey}.title`)}</p>
      {isError ?
        <ErrorBlock
          className={classes.errorBlock}
          text={t(`${tKey}.error_text`)}
        />
        :
        <div className={classes.methods}>
          {!methods
            ? <Skeleton />
            :
            methods.map(item => (
              <DeliveryMethod
                deliverier={item.deliverier}
                delivery_interval={item.delivery_interval}
                price={item.price}
                currency={item.currency}
                isActive={activeMethod?.id === item.id}
                onMethodClick={handleMethodClick}
                id={item.id}
              />
            ))
          }
        </div>
      }
      <p className={classes.footnote}>
        <T
          k={`${tKey}.footnote`}
          // eslint-disable-next-line
          components={{ serviceTermsLink: <a href={policyLinks.termsOfUse} className={classes.link} /> }}
        />
      </p>
      <CompleteButton
        title={t(`shipping_information.form.button_text`)}
        disabled={!activeMethod || isError}
        onCompleteButtonClick={handleCompleteButtonClick}
      />
    </div>
  )
}