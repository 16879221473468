import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';
import { SIGNS_ICONS_MATTE } from "@web-solutions/natal-chart/signs-icons"

import { getPersonalizedData } from 'src/store/profile/selectors';

import { ZodiacSkeleton } from 'src/components/skeleton';

import { PLANET_IDS } from '../../constants';

import { useHousesAndPlanetsData } from './hooks/useHousesAndPlanetsData';

import classes from './style.module.scss';

const tKey = 'landing.birth_chart';

const planetsArr = [PLANET_IDS.ASCENDANT, PLANET_IDS.SUN, PLANET_IDS.MOON]

interface Props {
  className?: string;
  skeletonClassName?: string;
}

export const Signs: React.FC<Props> = ({ className, skeletonClassName }) => {
  const personalizedData = useSelector(getPersonalizedData);
  const isPersonalized = !!personalizedData;
  const housesAndPlanets = useHousesAndPlanetsData(personalizedData);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (housesAndPlanets) {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 500);

      return () => clearTimeout(timer);
    }

  }, [housesAndPlanets]);

  const planets = housesAndPlanets?.reduce((acc, curr) => {
    if (planetsArr.includes(curr.name?.toLowerCase() as PLANET_IDS)) {
      return [curr, ...acc]
    }
    return acc
  }, [] as typeof housesAndPlanets)

  return (
    <div className={classes.container}>
      {(!isPersonalized || !housesAndPlanets) ?
        <ZodiacSkeleton className={classNames(skeletonClassName)} />
        :
        <div className={classNames(classes.signs, className, { [classes.visible]: isVisible })}>
          {planets?.map((item) => {
            const signImageSource = SIGNS_ICONS_MATTE[item?.sign?.toLowerCase() as keyof typeof SIGNS_ICONS_MATTE];
            const tPlanet = item?.name?.toLowerCase();

            return <div key={item?.name} className={classes.sign}>
              <img src={signImageSource?.src} srcSet={signImageSource?.srcSet} alt='sign' className={classes.img} />
              <p className={classes.name}>{t(`ELEMENTS.${item.sign.toUpperCase()}`)}</p>
              <p className={classes.planet}>{t(`${tKey}.${tPlanet}`)}</p>
            </div>
          })}
        </div>
      }
    </div>
  )
}