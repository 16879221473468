import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';
import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from 'src/constants/events';
import { usePersonalizationPopupContext } from 'src/hooks/use-personalization-popup-context';
import { getPersonalizedData } from 'src/store/profile/selectors';
import { ROUTES } from 'src/constants/routes';
import { useShopNavigation } from 'src/hooks/use-shop-navigation';
import { selectCartProducts } from 'src/store/cart/selectors';
import { useRemoteConfig } from 'src/hooks/use-remote-config';
import { setCartProducts } from 'src/store/cart/actions';

import classes from './style.module.scss';

const tKey = 'landing.welcome';

export const Header = () => {
  const dispatch = useDispatch();
  const { navigateTo } = useShopNavigation();

  const { onOpen } = usePersonalizationPopupContext();

  const { shopMode, product } = useRemoteConfig();

  const personalizedData = useSelector(getPersonalizedData);
  const products = useSelector(selectCartProducts);

  const isPersonalized = !!personalizedData;

  const handleClick = () => {

    if (isPersonalized) {
      Analytics.trackEvent('welcome', EVENT_ACTION.SUCCESS);
      if (shopMode === 'manual_add_to_cart' && !products.length) {
        dispatch(setCartProducts(([product])));
      }
      navigateTo(ROUTES.CART);
    } else {
      Analytics.trackEvent('welcome', EVENT_ACTION.CLICK);
      onOpen();
    }
  }

  const handleScroll = (id: string) => () => {
    Analytics.trackEvent('welcome', EVENT_ACTION.CLICK, { id });
    const elem = document.getElementById(id);
    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return <div className={classNames(classes.header, {
    [classes.personal]: isPersonalized,
  })}>
    <div onClick={handleScroll('book')} className={classes.menu_item}>
      {t(`${tKey}.header.book`)}
    </div>
    <div onClick={handleScroll('reviews')} className={classes.menu_item}>
      {t(`${tKey}.header.review`)}
    </div>
    <div onClick={handleScroll('faq')} className={classes.menu_item}>
      {t(`${tKey}.header.faq`)}
    </div>
    <div onClick={handleClick} className={classes.menu_button}>
      {!!products.length ? t(`${tKey}.header.cart_book`, { amount: products.length }) : t(`${tKey}.header.order`)}
    </div>
  </div>
}

export default Header;